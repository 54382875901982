import React, { FunctionComponent, useEffect, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, FreeMode, Navigation } from "swiper";
import Section from "../Section";
import "./styles.scss";
import RichText from "../RichText";

interface BluePrintSliderTalentInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  slides: any[];
}

const BluePrintSlider: FunctionComponent<BluePrintSliderTalentInterface> = props => {
  const { palleteColorList, slides } = props;
  const [width, setWidth] = useState(0);
  const [thumbnailsSwiper, setThumbnailsSwiper] = useState(null);
  const [bodyTextSwiper, setBodyTextSwiper] = useState(null);

  const breakPointsOptions = {
    centeredSlides: false,
    initialSlide: 0,
    scrollbar: {
      draggable: true
    },
    spaceBetween: 50
  };

  const setBreakPoints = {
    375: {
      ...breakPointsOptions,
      spaceBetween: 16,
      slidesPerView: 3,
      centeredSlides: true
    },
    768: {
      ...breakPointsOptions,
      slidesPerView: 2
    },
    1024: {
      ...breakPointsOptions,
      slidesPerView: 3
    },
    1440: {
      ...breakPointsOptions,
      slidesPerView: 3.5
    }
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Section sectionClassName="c-bluePrint-slider" theme={palleteColorList?.bgColor.title}>
      <Swiper
        key="bluePrintSlider"
        preloadImages={true}
        centeredSlides={true}
        initialSlide={0}
        slidesPerView={"auto"}
        spaceBetween={50}
        simulateTouch={true}
        lazy={true}
        freeMode={false}
        loop={false}
        scrollbar={{ draggable: false }}
        preventClicks={false}
        breakpoints={setBreakPoints}
        watchSlidesProgress={true}
        grabCursor={true}
        modules={[FreeMode, Navigation, Controller]}
        onSwiper={setThumbnailsSwiper}
        controller={{ control: bodyTextSwiper }}
        navigation
        className={`c-bluePrint-slider__thumbnails ${slides.length <= 3 && width > 768 ? "swiper-no-swiping" : ""}`}
      >
        {slides.map((slide: any, index: number) => {
          const { title, subtitle, _rawDescription, image } = slide;

          return (
            <SwiperSlide
              className="swiper-slide-animation"
              key={`slider-${index}`}
              data-testid={`playlist-slide-${index}`}
              style={{ width: "inherit", height: "auto" }}
            >
              <article className="c-bluePrint-slider__slide">
                <div className="c-bluePrint-slider__slide-image">
                  <SanityImage
                    {...image}
                    sizes="(max-width: 768px) 140px, (min-width: 768px) 312px 437px"
                    width={312}
                    height={437}
                    className={`c-bluePrint-slider__slide-image-img`}
                  />
                </div>
                <div className="c-bluePrint-slider__slide-bodyText">
                  <p className="c-bluePrint-slider__slide-title">{title}</p>
                  <p className="c-bluePrint-slider__slide-subtitle">{subtitle}</p>
                  <RichText data={_rawDescription} />
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        key="bluePrintSliderBodyText"
        preloadImages={true}
        centeredSlides={false}
        slidesPerView={1}
        spaceBetween={50}
        simulateTouch={true}
        lazy={true}
        freeMode={false}
        loop={false}
        scrollbar={{ draggable: false }}
        preventClicks={false}
        watchSlidesProgress={true}
        grabCursor={true}
        modules={[Controller]}
        onSwiper={setBodyTextSwiper}
        controller={{ control: thumbnailsSwiper }}
        className={`c-bluePrint-slider__text ${slides.length <= 3 && width > 768 ? "swiper-no-swiping" : ""}`}
      >
        {slides.map((slide: any, index: number) => {
          const { title, subtitle, _rawDescription } = slide;

          return (
            <SwiperSlide
              className="swiper-slide-animation"
              key={`slider-${index}`}
              data-testid={`playlist-slide-${index}`}
              style={{ width: "inherit", height: "auto" }}
            >
              <article className="c-bluePrint-slider__slide">
                <div className="c-bluePrint-slider__slide-bodyText">
                  <p className="c-bluePrint-slider__slide-title">{title}</p>
                  <p className="c-bluePrint-slider__slide-subtitle">{subtitle}</p>
                  <RichText data={_rawDescription} />
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Section>
  );
};

export default BluePrintSlider;
